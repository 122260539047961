<script setup lang="ts">
useHead({
  title: 'Miss W Global',
  meta: [
    {
      name: 'description',
      content: 'Miss W The Influence',
    },
    {
      name: 'theme-color',
      content: () => isDark.value ? '#fe0000' : '#ffffff',
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: () => '/favicon.webp',
    },
  ],
})
</script>

<template>
  <RouterView />
</template>
